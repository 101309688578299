@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

html,
body {
  scroll-behavior: smooth;
}
.scroll-offset {
  scroll-margin-top: 80px; /* Adjust this value to match the height of your fixed navbar */
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Edge */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For IE 11 */
input[type='number']::-ms-inner-spin-button,
input[type='number']::-ms-outer-spin-button {
  display: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-in-out;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-text {
  animation: slideInFromTop 0.5s ease-out forwards;
}

.product-card-shadow {
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px -3px 4px 0px rgba(0, 0, 0, 0.1);
}
.quick-view-modal {
  box-shadow: 7px 7px 79px 0px rgba(0, 0, 0, 0.48);
}
.home-product-section {
  @apply mt-2 grid grid-cols-2 gap-2 md:mt-5 md:grid-cols-3 md:gap-5 xl:grid-cols-3;
}
.home-heading {
  @apply no-scrollbar mx-auto flex max-w-6xl flex-row items-center gap-2 md:gap-4 lg:gap-6 overflow-x-scroll p-2;
}

/* // image slider css */

.slide {
  min-width: 100%;
  height: 100%;
  transition: 0.5s;
  overflow: hidden;
}
#leftBtn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: 0.5s;
  &:hover {
    background: rgba(0, 0, 0, 0.421);
    cursor: pointer;
    i {
      color: white;
    }
  }
}

#rightBtn {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: 0.5s;
  &:hover {
    background: rgba(0, 0, 0, 0.421);
    cursor: pointer;
    i {
      color: white;
    }
  }
}
.brand-page-nav {
  clip-path: polygon(100% 0, 91% 100%, 91% 100%, 0 100%, 0 0);
}

.wishlist-container {
  clip-path: polygon(16% 0, 100% 0, 100% 50%, 100% 100%, 17% 100%, 5% 49%);
}

/* For modern browsers */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
  height: 12px; /* Set the height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px; /* Round corners */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovered */
}

/* For Firefox (uses scrollbar-width and scrollbar-color) */
* {
  scrollbar-width: thin; /* Options: auto, thin */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
}
